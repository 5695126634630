import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.scss';
import LazySection from './components/LazySection';
import Preloader from './components/Preloader/Preloader';

const Navigation = React.lazy(() => import('./components/Navigation/Navigation'));
const FirstSection = React.lazy(() => import('./sections/MainSection/MainSection'));
const HomeCategories = React.lazy(() => import('./sections/CategoriesHomePage/CategoriesHomePage'));
const HomeCatalog = React.lazy(() => import('./sections/CatalogHomePage/HomeCatalog'));
const AboutSection = React.lazy(() => import('./sections/TriggerHomePage/TriggerHomePage'));
const HomeSertificats = React.lazy(() => import('./sections/SertificatsSection/Sertificats'));
const HomeAboutUs = React.lazy(() => import('./sections/AboutUs/AboutUs'));
const CallbackForm = React.lazy(() => import('./sections/CallbackSection/CallbackSection'));
const Footer = React.lazy(() => import('./sections/FooterSection/FooterSection'));
const PrivacyModal = React.lazy(() => import('./components/PrivacyModal/PrivacyModal'));
const PrivacyPolicy = React.lazy(() => import('./sections/PrivacyPolicy/PrivacyPolicy'));
const CookiePolicy = React.lazy(() => import('./sections/PrivacyPolicy/CookiePolicy'));

function App() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [firstScreenLoaded, setFirstScreenLoaded] = useState(false);

    const handleImagesLoaded = () => {
        setFirstScreenLoaded(true);
    };

    useEffect(() => {
        if (firstScreenLoaded) {
            setIsLoaded(true);
        }
    }, [firstScreenLoaded]);

    return (
        <>
            {!isLoaded && <Preloader onLoadComplete={handleImagesLoaded} />}
            {isLoaded && (
                <div className="App">
                    <Router>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Navigation />
                            <Routes>
                                <Route
                                    path="/"
                                    element={
                                        <>
                                            <FirstSection onImagesLoaded={handleImagesLoaded} />
                                            <LazySection>
                                                <HomeCategories />
                                                <HomeCatalog />
                                                <AboutSection />
                                                <HomeSertificats />
                                                <HomeAboutUs />
                                                <CallbackForm />
                                                <Footer />
                                            </LazySection>
                                            <PrivacyModal />
                                        </>
                                    }
                                />
                                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                                <Route path="/cookie-policy" element={<CookiePolicy />} />
                            </Routes>
                        </Suspense>
                    </Router>
                </div>
            )}
        </>
    );
}

export default App;
